import React from 'react';
import Cookies from 'universal-cookie';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }){

    const cookies = new Cookies();

    return(
        <CustomerContext.Provider value={
            {   
                cookies
            }
        }>
        {children}
    </CustomerContext.Provider>
)
}

export default CustomerContext;