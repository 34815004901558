import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';  
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { useContext } from 'react';
import CustomerContext from '../Authentication/Context/CustomerContext'
import fetchPost from '../CustomHooks/fetchPost';

function OptOutForm({ 
    defaultOptOuts,
    phone,
    email
}) {
    const { cookies } = useContext(CustomerContext)
    const [lockForm, setLockForm] = useState(false)
    const [code, setCode] = useState();
    const [postObj, setPostObj] = useState(defaultOptOuts);
    const { REACT_APP_API_URL } = process.env;
    const [, setPostData] = useState();
    const [postStatus, setPostStatus] = useState();
    const [postLoading, setPostLoading] = useState();
    const [postError, setPostError] = useState();

    const handleCheckboxChange = (e,fieldName) => { 
        let newPostObj = {...postObj}
        newPostObj[fieldName] = e.target.checked;
        setPostObj(newPostObj)
    }

    const handleSubmit = () => { 
        let attempts = cookies.get('tokenAttempts');
        if(postError==='Invalid Token'){
           if(attempts){
            if(attempts>=3){
                setLockForm(true)
            }else{
                cookies.set('tokenAttempts',Number(attempts)+1)
            }
           }else{
            cookies.set('tokenAttempts',1)
           }
        }
        if(attempts){
            if(attempts>=3){
                setLockForm(true)
            }else{
                fetchPost({
                    url:`${REACT_APP_API_URL}/EmployeesApply/OptOutComs?Token=${code}`,
                    postObj:postObj,
                    setLoading:setPostLoading,
                    setStatus:setPostStatus,
                    setData:setPostData,
                    setError:setPostError,
                    count:0
                })
            }
        }
    }

  return (
    <div>
        <Alert>A code was sent to your device. You have 24 hours to input the code before it expires</Alert>
        <Form.Label className='full-width'>Code</Form.Label>
        <Form.Control onBlur={(e)=>setCode(e.target.value)}  className='margin-bottom'/>
        <h5>A check mark means you would like to receive notifications</h5>
        <Row className='margin-bottom'>
            <Col>
                <b>Contact Reason</b>
            </Col>
            <Col sm={2}>
                <b>Email</b>
            </Col>
            <Col sm={2}>
                <b>Text</b>
            </Col>
            <Col sm={2}>
                <b>Call</b>
            </Col>
        </Row>
        <Row className='margin-bottom'>
            <Col>
                <h6>Job Opportunities</h6>
                {/* <p>Examples: Job openings in your area, Jobs you might like</p> */}
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.JobsEmail} onChange={(e)=>handleCheckboxChange(e,'JobsEmail')} />
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.JobsSMS} onChange={(e)=>handleCheckboxChange(e,'JobsSMS')} />
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.JobsCall} onChange={(e)=>handleCheckboxChange(e,'JobsCall')} />
            </Col>
        </Row>
        <Row  className='margin-bottom'>
            <Col>
                <h6>Employnet News</h6>
                {/* <ul>
                    <li>Monthly newsletter</li>
                    <li>Branch updates</li>
                    <li>Local job news</li>
                </ul> */}
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.NewsletterEmail} onChange={(e)=>handleCheckboxChange(e,'NewsletterEmail')} />
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.NewsletterSMS} onChange={(e)=>handleCheckboxChange(e,'NewsletterSMS')} />
            </Col>
            <Col  sm={2}>
            </Col>
        </Row>
        <Row  className='margin-bottom'>
            <Col>
                <h6>Celebrations</h6>
                <p>(Birthday, holidays, etc.)</p>
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.CelebrationsEmail} onChange={(e)=>handleCheckboxChange(e,'CelebrationsEmail')} />
            </Col>
            <Col  sm={2}>
                <Form.Check checked={postObj.CelebrationsSMS} onChange={(e)=>handleCheckboxChange(e,'CelebrationsSMS')} />
            </Col>
            <Col  sm={2}>
            </Col>
        </Row>
        <Row className='contact-update-footer'>
            {(postError&&postStatus!==200)&&
            postError==='Contact Branch'?
                <Alert variant='warning'>There is an issue with your account, please contact your local branch to resolve the issue.<br/><a href='https://employnet.com/locations/'> Click here to find your branch</a></Alert>
            :postError==='Invalid Token'?
                <Alert variant='warning'>There was an issue, please make sure you entered the code correctly and try again</Alert>
            :null
            }
            {(postStatus===200)?
                <Alert>Success! Your contact preferences have been updated</Alert>
            :lockForm?
                <Alert variant='warning'>You have entered the wrong code too many times, please contact your branch to resolve the issue. <br/><a href='https://employnet.com/locations/'> Click here to find your branch</a></Alert>
            :
                <Button variant='success' onClick={()=>handleSubmit() }>{postLoading?<Spinner animation='border' size='sm'/>:'Update Contact Preferences'}</Button>
            }
            
        </Row>
    </div>
  )
}

export default OptOutForm