import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { CustomerProvider } from './Components/Authentication/Context/CustomerContext'
import FourZeroFour from './Components/Layout/FourZeroFour';
import Layout from './Components/Layout/Layout'
// import LandingPage from './Components/LandingPage/LandingPage';
// import LoginPage from './Components/Login/LoginPage';
// import ViewOrder from './Components/ViewOrder/ViewOrder';
// import ProfilePage from './Components/ProfilePage/ProfilePage';
import OptOut from './Components/OptOut/OptOut';
import OptOutLogin from './Components/OptOut/OptOutLogin';
import Privacy from './Components/Privacy';
import CacheBuster from './Components/Layout/CacheBuster';
import { Component } from 'react';

class App extends Component {

  render(){
    return (
      <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
          return (
        <CookiesProvider>
          <CustomerProvider>
            <BrowserRouter>
              <Layout>  
                <Routes>
                  <Route exact path='/' element={<OptOutLogin />} />
                  {/* <Route path='/login' element={<LoginPage />} /> */}
                  {/* <Route path='/Profile' element={<ProfilePage />} /> */}
                  {/* <Route path='/Order/:OrderID' element={<ViewOrder />} /> */}
                  <Route path='/Privacy' element={<Privacy />} />
                  <Route path='/OptOut' element={<OptOut />} />
                  <Route path='/OptOut/Login' element={<OptOutLogin />} />
                  <Route path='*' element={<FourZeroFour />} />
                </Routes>
              </Layout>
            </BrowserRouter>
          </CustomerProvider>
        </CookiesProvider>
      )}}
    </CacheBuster>
    )}
}

export default App;
