import React from 'react'
import './Layout.css'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { BsList } from 'react-icons/bs'
// import CustomerContext from '../Authentication/Context/CustomerContext'
// import Nav from 'react-bootstrap/Nav'
// import { useLocation } from 'react-router-dom'

function Header() {

  // const { cookies } = useContext(CustomerContext)
  // const location = useLocation();

  // const Logout = () => { 
  //   cookies.remove('customer', { path:'/' })
  //   window.location.reload()
  //  }
  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Navbar.Brand href='/OptOut/login' className='brand'><img alt='Employnet tricolor circular logo' className='logo' src="/favicon.ico" />Employnet</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"><BsList /></Navbar.Toggle>
        {/* <Navbar.Collapse className='navs'>
            <Nav>
              {cookies.get('customer')==null?
              <Nav.Link href={`/login?redirectUrl=${location.pathname}`}>Login</Nav.Link>
              :
              <>
              <Nav.Link onClick={Logout}>Logout</Nav.Link>
              </>
              }
            </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}

export default Header;