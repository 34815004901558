import React from "react";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({children}) => {

    return(
        <>
        <Header />
        <Container className="app-container">
            {children}
        </Container>
        <Footer />
        </>
    )
}

export default Layout;