import { useState, useEffect } from "react";
import PropTypes from 'prop-types';

const useFetchGet = ( url, cookies ) => {
    const [data, setData] = useState();
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortController = new AbortController();

    useEffect(() => {
      if(url){
        fetchData(0);
      }
      return ()=>{
        abortController.abort();
      } 
   // eslint-disable-next-line
    }, [url]);

    const fetchData = (count) => {
      if(count<3){
        setLoading(true)
        fetch(url,{ signal: abortController.signal })
        .then(res => {
          setStatus(res.status)
          setLoading(false)
          if(res.status===200){
            return res.text()
          }else if(res.status===401){
            cookies.remove('customer', {path:'/'})
            window.location.reload();
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              fetchData(count++)
            }, 1000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  fetchData(count++)
                }, 1000);
              }else{
                setError(res.statusText)
                throw Error(res.statusText)
              }
            })
          }
        })
        .then(data=>{
          if(data){
            let parsedData = JSON.parse(data)
            setData(parsedData)
          }
        })
        .catch(error => {
          setError(error);
        });
      };
    }


    return { error, loading, data, status, setData, fetchData };
  };

  useFetchGet.propTypes = {
    url: PropTypes.string.isRequired,
    cookies: PropTypes.object.isRequired
  }

  export default useFetchGet;