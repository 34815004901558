import React from 'react'
import { useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useContext } from 'react';
import CustomerContext from '../Authentication/Context/CustomerContext';

function OptOutLogin() {
    const {cookies} = useContext(CustomerContext)
    const [phone,setPhone] = useState();
    const [email,setEmail] = useState();
    const [emailError, setEmailError] = useState();
    const [phoneError, setPhoneError] = useState();
    const navigate = useNavigate();
    const { REACT_APP_API_URL } = process.env;
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(()=>{
        if(status){
            if(!error&&status===200){
                navigate('/OptOut',{state:{phone, email}})
            }
        }
    // eslint-disable-next-line
    },[status])
    
    const handleSubmit = (e) => { 
        e.preventDefault();
        if(!emailError&&!phoneError){
            if(phone&&phone!==''){
                fetchData(`${REACT_APP_API_URL}/EmployeesApply/GetOptOutToken?Phone=${phone}`,0)
            }else if(email&&email!==''){
                fetchData(`${REACT_APP_API_URL}/EmployeesApply/GetOptOutToken?Email=${email}`,0)
            }else{
                setError('Please enter a phone or email address')
            }
        }
    }

    const handleChangeEmail = (e) => { 
        setError(null)
        setPhoneError(null)
        if(e.target.value===''){
            setEmail('')
        }else if(validateEmail(e.target.value)){
            setEmailError(null)
            setEmail(e.target.value)
        }else{
            setEmailError('Email is invalid')
        }
    }

    const handleChangePhone = (e) => {
        setError(null) 
        setEmailError(null)
        if(e.target.value===''){
            setPhone('')
        }
        if(e.target.value.length>9){
            if(validatePhoneNumber(e.target.value)){
                setPhoneError(null)
                setPhone(e.target.value)
            }else{
                setPhoneError('Phone number is invalid, please try again')
            }
        }
    }

    function validatePhoneNumber(input_str) {
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      
        return re.test(input_str);
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    const fetchData = (apiUrl, count) => {
        if(count<3){
          cookies.set('tokenAttempts',1)
          setLoading(true)
          fetch(apiUrl)
          .then(res => {
            setStatus(res.status)
            setLoading(false)
            if(res.status===200){
              return res.text()
            }else if(res.status===503||res.status===500){
              setTimeout(() => {
                fetchData(apiUrl, count++)
              }, 1000);
              return [];
            }else if(!res.ok){
              res.text().then(text=>{
                if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                  setTimeout(() => {
                    fetchData(apiUrl, count++)
                  }, 1000);
                }else if(text==='Contact Branch'){
                    setError(text)
                    throw Error(text)
                }else{
                    setError(res.statusText)
                    throw Error(res.statusText)
                }
              })
            }
          })
          .then(data=>{
            if(data){
                return data;
            }
          })
          .catch(error => {
            setError(error);
          });
        };
      }

  return (
    <div className='login-wrapper'>
        <Card className="login-card">
            <Form onSubmit={handleSubmit}>
                <Card.Header>
                    <h2  className='center-text'>Verify Contact Information</h2>
                </Card.Header>
                <Card.Body className='center-text'>
                    <Alert>
                        We need to verify you are the authorized user of your accounts before we can make changes to your options. Please enter either your email address or phone number and we will send you a verification code to be entered on the next page
                    </Alert> 
                    <Form.Label>Phone</Form.Label>
                    <Form.Control maxLength={10} placeholder='123456789' onChange={handleChangePhone}/>
                    {phoneError&&
                    <Alert variant='warning'>{phoneError}</Alert>}

                    <div className='or-separator'>
                        <div></div>
                        <Form.Text muted>or<br/></Form.Text>
                        <div></div>
                    </div>

                    <Form.Label>Email</Form.Label>
                    <Form.Control placeholder='john.doe@example.com' onChange={handleChangeEmail} className='margin-bottom'/>

                    {emailError&&
                    <Alert variant='warning'>{emailError}</Alert>}

                    {(error&&error==='Contact Branch')?
                        <Alert variant='warning'>
                            We are unable to process your request with the contact information provided.
                            <br/> Please contact the branch to resolve this error. Thank you!
                            <br/><a href='https://employnet.com/locations/'> Click here to find your branch</a>
                        </Alert>
                    :error?
                        <Alert variant='warning'>{error}</Alert>
                    :''}

                </Card.Body>
                <Card.Footer>
                    <Button type='submit'>{loading?<Spinner animation='border' size='sm'/>:'Submit'}</Button>
                </Card.Footer>
            </Form>
        </Card>
    </div>
  )
}



export default OptOutLogin