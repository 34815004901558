import useFetchGet from '../CustomHooks/useFetchGet'
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'
import { useLocation
 } from 'react-router-dom'
import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import './OptOut.css'
import OptOutForm from './OptOutForm';

function OptOut() {
    const location = useLocation();
    const [phone] = useState(location.state?.phone)
    const [email] = useState(location.state?.email)
    const { REACT_APP_API_URL } = process.env;
    const { 
      data: defaultOptOuts, 
      status: defaultOptOutStatus, 
      loading: defaultOptOutLoading, 
      error: defaultOptOutError 
    } = useFetchGet(`${REACT_APP_API_URL}/EmployeesApply/OptOutComs${phone?`?Phone=${phone}`:email?`?Email=${email}`:''}`, 'cookies')

  return (
    <div  className='card-center'>
      <Card className='margin-top half-width preferences-selection'>
        <Card.Header><h2>Update Contact Preferences</h2></Card.Header>
        <Card.Body>
          {defaultOptOutLoading?
            <Spinner animation='border'/>
          :defaultOptOuts&&
            <OptOutForm defaultOptOuts={defaultOptOuts} phone={phone} email={email} />
          }
          {(defaultOptOutError&&defaultOptOutStatus!==200)&&
            <Alert>
              We are unable to process your request because your email address or phone number is associated with more than one account in our system.  Please contact the branch to resolve this error.  Thank you!
              <br/><a href='https://employnet.com/locations/'> Click here to find your branch</a>
            </Alert>
          }
        </Card.Body>
      </Card>
    </div>
  )
}

export default OptOut