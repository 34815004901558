import React from 'react'
import { Container } from 'react-bootstrap'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './Layout.css'

function Footer() {
  return (
    <Navbar className='footer' as='footer'>
        <Container>
            <Navbar.Collapse>
                <Nav className="footer-navs">
                    <Nav.Link href="/Privacy">Privacy</Nav.Link>
                    <Nav.Link href="/">Contact Preferences</Nav.Link>
                    <Nav.Link variant="dark" title='Report a bug' onClick={(e) => {
                      window.location.href = `mailto:${'Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>'}` 
                      e.preventDefault()
                    }}>Report an Issue</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}

export default Footer