import PropTypes from 'prop-types';

const fetchPost = (params) => {
    let {url, postObj, setLoading, setStatus, setData, setError, count} = params
    setLoading(true);
    if(count<3){
      fetch(url,{
          method: 'POST',
          headers: {
              'Content-Type':'application/json'
          },
          body: JSON.stringify(postObj)
      })
      .then(res => {
        setLoading(false)
        setStatus(res.status)
        if(res.status===200){
          return res.text()
        }else if(res.status===400){
          res.text().then(text=>{
            if(text==='The wait operation timed out.'){
              setTimeout(() => {
                fetchPost(url, postObj, setLoading, setStatus, setData, count++)
              }, 1000);
            }else{
              setError(text)
            }
          })
        }else if(res.status===503||res.status===500){
          setTimeout(() => {
            fetchPost(url, postObj, setLoading, setStatus, setData, count++)
          }, 1000);
          return [];
        }else if(!res.ok){
          res.text().then(text=>{
            if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
              setTimeout(() => {
                fetchPost(url, postObj, setLoading, setStatus, setData, count++)
              }, 1000);
            }else{
              throw Error(res.statusText)
            }
          })
        }
      })
      .then(data=>{
        if(data){
          let parsedData = JSON.parse(data)
          setLoading(false);
          setData(parsedData)
        }
      })
      .catch(error => {
        setLoading(false)
        setError(error);
      });
    };
  }

fetchPost.PropTypes = {
    params: PropTypes.shape({
        url: PropTypes.string.isRequired,
        postObj: PropTypes.object, 
        setLoading: PropTypes.func,
        setStatus: PropTypes.func,
        setData: PropTypes.func, 
        setError: PropTypes.func, 
        count: PropTypes.number
    })
}

export default fetchPost;